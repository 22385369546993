import React, { useEffect, useState } from "react";
import "./About.css";
import { No_IMG } from "../../assets";
import { client, urlFor } from "../../client";
import { BodyText } from "../../components";
const About = ({ AboutRef }) => {
  const [Abouts, setAbouts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "tresci"]{
          tresci_about 
        }`
      )
      .then((data) => {
        setAbouts(data);
      })
      .catch(console.error);
  }, []);
  return (
    <div className="about__kontener sekcja-padding" ref={AboutRef}>
      <div className="about__text-kontener">
        <div className="about__title-section">
          <div className="title__h2">
            {Abouts.map((data, index) => (
              <h2 key={data + index}>{data.tresci_about.about__title}</h2>
            ))}
            <span></span>
          </div>
          <div className="subtitle__h4">
            {Abouts.map((data, index) => (
              <h4 key={data + index}>{data.tresci_about.about__subtitle}</h4>
            ))}
          </div>
          <div className="about__text-section">
            {Abouts.map((data, index) => (
              <BodyText
                doc={data.tresci_about}
                key={index + data.tresci_about.about__title}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="about__img-kontener">
        {Abouts.map((data, index) => (
          <img
            key={data + index}
            src={
              data.tresci_about.about_img === undefined ||
              data.tresci_about.about_img === null
                ? No_IMG
                : urlFor(data.tresci_about.about_img)
            }
            alt={
              data.tresci_about.about__title === null ||
              data.tresci_about.about__title === undefined
                ? "about_img"
                : data.tresci_about.about__title
            }
          />
        ))}
      </div>
    </div>
  );
};

export default About;
