import { useRef, useEffect, useState } from "react";
import "./App.css";
import {
  Nawigacja,
  Header,
  About,
  Oferta,
  Kontakt,
  Footer
} from "./containers";
import { client, urlFor } from "./client";
import { No_LOGO, favicon } from "./assets";
import ReactGA from "react-ga4";
import posthog from "posthog-js";
import { CookieBanner, PolitykaPrywatnosci } from "./components";

//Ciastka
posthog.init("phc_It2CeekK28CNy1Mptrd6ARQXJruy8ErUEFkJDbGYBcD", {
  api_host: "https://eu.posthog.com"
});

function App() {
  const AboutRef = useRef(null);
  const OfferRef = useRef(null);
  const KontaktRef = useRef(null);
  const [DaneFirmy, setDaneFirmy] = useState([]);
  const [LogoData, setLogoData] = useState([]);
  const [socialMediaLinks, setsocialMediaLinks] = useState([]);
  const [favIconData, setfavIconData] = useState([]);
  const [SeoData, setSeoData] = useState([]);
  const [politykaPrywatnosciPage, setpolitykaPrywatnosciPage] = useState(false);
  let Logo;
  let FacebookLink;
  let InstagramLink;
  let YouTubeLink;

  useEffect(() => {
    client
      .fetch(
        `*[_type == "ustawienia"]{
      dane_firmy
    }`
      )
      .then(data => setDaneFirmy(data))
      .catch(error => console.error);

    client
      .fetch(
        `*[_type == "ustawienia"]{
      logo_firmy{
        logo_img
      }
    }`
      )
      .then(data => {
        setLogoData(data);
      })
      .catch(error => error.console);

    client
      .fetch(
        `*[_type == "ustawienia"]{
      social_media
    }`
      )
      .then(data => setsocialMediaLinks(data))
      .catch(error => error.console);

    client
      .fetch(
        `*[_type == "ustawienia"]{
      logo_firmy{
        logo_icon
      }
    }`
      )
      .then(data => setfavIconData(data))
      .catch(error => error.console);
    client
      .fetch(
        `*[_type == "ustawienia"]{
      seo
    }`
      )
      .then(data => setSeoData(data))
      .catch(error => error.console);
  }, []);

  // LOGO

  LogoData.map(data => {
    if (data.logo_firmy === null) {
      return (Logo = No_LOGO);
    } else {
      return (Logo = urlFor(data.logo_firmy.logo_img));
    }
  });

  // FAVICON

  let headTitle = document.querySelector("head");
  let setFavicon = document.createElement("link");

  favIconData.map(data => {
    if (data.logo_firmy === null) {
      setFavicon.setAttribute("rel", "shortcut icon");
      setFavicon.setAttribute("href", favicon);
      headTitle.appendChild(setFavicon);
    } else if (data.logo_firmy) {
      setFavicon.setAttribute("rel", "shortcut icon");
      setFavicon.setAttribute("href", urlFor(data.logo_firmy.logo_icon));
      headTitle.appendChild(setFavicon);
    }
  });

  // META TREŚCI

  let webTitle = "";
  let webKeyWords = "";
  let webDesciption = "";

  SeoData.map(data => {
    if (data.seo === null || data.seo === undefined) {
      webTitle = "Tytuł Strony";

      webKeyWords = "Słowa Kluczowe";
      webDesciption = "Opis Strony";
    } else {
      if (data.seo.seo_title === null || data.seo.seo_title === undefined) {
        webTitle = "Tytuł Strony";
      } else {
        webTitle = data.seo.seo_title;
      }
      if (
        data.seo.seo_keywords === null ||
        data.seo.seo_keywords === undefined
      ) {
        webKeyWords = "Słowa Kluczowe";
      } else {
        webKeyWords = data.seo.seo_keywords;
      }
      if (
        data.seo.seo_description === null ||
        data.seo.seo_description === undefined
      ) {
        webDesciption = "Opis Strony";
      } else {
        webDesciption = data.seo.seo_description;
      }
    }
  });

  // TITLE

  const pageTitle = document.querySelector("title");
  pageTitle.innerHTML = webTitle;

  const pageKeyWords = document.querySelector('meta[name="keywords"]');
  pageKeyWords.content = `${webKeyWords}`;

  const pageDesc = document.querySelector('meta[name="description"]');
  pageDesc.content = `${webDesciption}`;

  // SOCIAL MEDIA LINKS

  socialMediaLinks.map(data => {
    if (data.social_media === null) {
      FacebookLink = "";
      InstagramLink = "";
      YouTubeLink = "";
      return FacebookLink, InstagramLink, YouTubeLink;
    } else {
      if (data.social_media.social_media_facebook === null) {
        FacebookLink = "";
      } else if (data.social_media.social_media_facebook) {
        FacebookLink = data.social_media.social_media_facebook;
      }
      if (data.social_media.social_media_instagram === null) {
        InstagramLink = "";
      } else if (data.social_media.social_media_instagram) {
        InstagramLink = data.social_media.social_media_instagram;
      }

      if (data.social_media.social_media_youtube === null) {
        YouTubeLink = "";
      } else if (data.social_media.social_media_youtube) {
        YouTubeLink = data.social_media.social_media_youtube;
      }
    }
  });

  return (
    <div className="App">
      {politykaPrywatnosciPage &&
        <PolitykaPrywatnosci
          setpolitykaPrywatnosciPage={setpolitykaPrywatnosciPage}
          Nazwa_Firmy={DaneFirmy.map(data => data.dane_firmy.dane_firmy_nazwa)}
          Nazwa_Ulicy={DaneFirmy.map(data => data.dane_firmy.dane_firmy_ulica)}
          Nr_Budynku={DaneFirmy.map(
            data => data.dane_firmy.dane_firmy_ulica_nr
          )}
          Telefon={DaneFirmy.map(data => data.dane_firmy.dane_firmy_telefon)}
          Kod_Pocztowy={DaneFirmy.map(
            data => data.dane_firmy.dane_firmy_kodPocztowy
          )}
          Miejscowosc={DaneFirmy.map(
            data => data.dane_firmy.dane_firmy_miejscowosc
          )}
          Nip={DaneFirmy.map(data => data.dane_firmy.dane_firmy_nip)}
          Mail={DaneFirmy.map(data => data.dane_firmy.dane_firmy_mail)}
        />}

      {posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing()
        ? null
        : <CookieBanner
            setpolitykaPrywatnosciPage={setpolitykaPrywatnosciPage}
          />}
      <Nawigacja
        AboutRef={AboutRef}
        OfferRef={OfferRef}
        KontaktRef={KontaktRef}
        Telefon={DaneFirmy.map(data => data.dane_firmy.dane_firmy_telefon)}
        FacebookLink={FacebookLink}
        InstagramLink={InstagramLink}
        YouTubeLink={YouTubeLink}
      />
      <Header
        AboutRef={AboutRef}
        OfferRef={OfferRef}
        KontaktRef={KontaktRef}
        Logo={Logo}
        Nazwa_Firmy={DaneFirmy.map(data => data.dane_firmy.dane_firmy_nazwa)}
      />
      <About AboutRef={AboutRef} />
      <Oferta OfferRef={OfferRef} />
      <Kontakt KontaktRef={KontaktRef} />
      <Footer
        Nazwa_Firmy={DaneFirmy.map(data => data.dane_firmy.dane_firmy_nazwa)}
        Nazwa_Ulicy={DaneFirmy.map(data => data.dane_firmy.dane_firmy_ulica)}
        Nr_Budynku={DaneFirmy.map(data => data.dane_firmy.dane_firmy_ulica_nr)}
        Telefon={DaneFirmy.map(data => data.dane_firmy.dane_firmy_telefon)}
        Kod_Pocztowy={DaneFirmy.map(
          data => data.dane_firmy.dane_firmy_kodPocztowy
        )}
        Miejscowosc={DaneFirmy.map(
          data => data.dane_firmy.dane_firmy_miejscowosc
        )}
        Nip={DaneFirmy.map(data => data.dane_firmy.dane_firmy_nip)}
        Mail={DaneFirmy.map(data => data.dane_firmy.dane_firmy_mail)}
        Logo={Logo}
        FacebookLink={FacebookLink}
        InstagramLink={InstagramLink}
        YouTubeLink={YouTubeLink}
      />
    </div>
  );
}

export default App;
