import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { Formik, Field, Form, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Kontakt.css";
import { EmailJSConfig } from "../../libs";
import { client } from "../../client";

const Kontakt = ({ KontaktRef }) => {
  const [mailValidationTrue, setmailValidationTrue] = useState(false);
  const [mailValidationFalse, setmailValidationFalse] = useState(false);
  const [mathQuestion, setMathQuestion] = useState(generateMathQuestion());
  const [userAnswer, setUserAnswer] = useState("");
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(1);
  const [kontaktData, setkontaktData] = useState([]);
  const [PocztaData, setPocztaData] = useState([]);

  function generateMathQuestion() {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    return `${num1} + ${num2}`;
  }

  useEffect(() => {
    client
      .fetch(
        `*[_type == "tresci"]{
      kontakt
    }`
      )
      .then((data) => setkontaktData(data))
      .catch(console.error);

    client
      .fetch(
        `*[_type == "ustawienia"]{
      poczta_ustawienia
    }`
      )
      .then((data) => setPocztaData(data))
      .catch((error) => error.console);

    setTimeout(() => {
      mailValidationTrue && setmailValidationTrue(false);
      mailValidationFalse && setmailValidationFalse(false);
    }, 3500);
  }, []);
  // Ustawienie Poczty
  let service = "";
  let template = "";
  let mailObject = "";

  PocztaData.map((data) => {
    if (data.poczta_ustawienia === null || data.poczta_ustawienia === null) {
      let service = "";
      let template = "";
      let mailObject = "";
    } else {
      if (
        data.poczta_ustawienia.emailjs_service === null ||
        data.poczta_ustawienia.emailjs_service === undefined
      ) {
        let service = "";
      } else {
        service = data.poczta_ustawienia.emailjs_service;
      }
      if (
        data.poczta_ustawienia.emailjs_template === null ||
        data.poczta_ustawienia.emailjs_template === undefined
      ) {
        let template = "";
      } else {
        template = data.poczta_ustawienia.emailjs_template;
      }
      if (
        data.poczta_ustawienia.emailjs_object === null ||
        data.poczta_ustawienia.emailjs_object === undefined
      ) {
        let mailObject = "";
      } else {
        mailObject = data.poczta_ustawienia.emailjs_object;
      }
    }
  });

  // console.log(service, template, mailObject);

  const SendEmail = (object, values) => {
    emailjs.send(service, template, object, mailObject).then(
      (result) => {
        setmailValidationTrue(true);
        console.log(result.text, "Wiadomość wysłana poprawnie");
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div className="kontakt__kontener sekcja-padding" ref={KontaktRef}>
      <div className="title__h2">
        {kontaktData.map((data, index) => (
          <h2 key={index + data.kontakt.kontakt_title}>
            {data.kontakt.kontakt_title === null ||
            data.kontakt.kontakt_title === undefined
              ? "Kontakt"
              : data.kontakt.kontakt_title}
          </h2>
        ))}

        <span></span>
      </div>
      <div className="subtitle__h4">
        {kontaktData.map((data, index) => (
          <h4 key={index + data.kontakt.kontakt__subtitle}>
            {data.kontakt.kontakt__subtitle === null ||
            data.kontakt.kontakt__subtitle === undefined
              ? ""
              : data.kontakt.kontakt__subtitle}
          </h4>
        ))}
      </div>
      <div className="formik__wrapper">
        <Formik
          initialValues={{
            nameAndsurname: "",
            email: "",
            phone: "",
            subject: "",
            textarea: ""
          }}
          validationSchema={Yup.object({
            nameAndsurname: Yup.string()
              .min(4, "Imię i naziwsko musi posiadać minimum 4 znaki")
              .max(30, "Imię i nazwisko musi posiadać maksimum 30 znaków")
              .required("Pole nie może być puste"),
            email: Yup.string()
              .email("Błędny format e-mail")
              .min(4, "E-mail musi posiadać minimum 4 znaki")
              .max(30, "E-mail musi posiadać maksimum 30 znaków")
              .required("Pole nie może być puste"),
            phone: Yup.number()
              .positive()
              .integer()
              // .string()
              // .max(12, "Telefon musi posiadać maksimum 12 znaków")
              .typeError("Pole musi zawierać tylko cyfry")
              .min(9, "Telefon musi posiadać minimum 9 znaków")
              .required("Pole nie może być puste"),
            subject: Yup.string()
              .min(4, "Temat musi posiadać minimum 4 znaki")
              .max(30, "Temat musi posiadać maksimum 30 znaków")
              .required("Pole nie może być puste"),
            textarea: Yup.string()
              .min(30, "Treść wiadomości musi posiadać minimum 30 znaków")
              .required("Pole nie może być puste")
          })}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              // Sprawdź, czy odpowiedź użytkownika jest poprawna
              if (parseInt(userAnswer) === eval(mathQuestion)) {
                setIsAnswerCorrect(true);
                SendEmail(values);
                actions.setSubmitting(false);
                actions.resetForm({
                  values: ""
                });
                setUserAnswer("");
                console.log(values);
              } else {
                setIsAnswerCorrect(false);
              }
            }, 1000);
          }}>
          <Form className="formik__container">
            <div className="inputs_container">
              <div className="formik_field-container">
                <Field
                  type="input"
                  className="formik__field"
                  name="nameAndsurname"
                  id="nameAndsurname"
                  required
                  placeholder="Imię i nazwisko"
                />
                <ErrorMessage
                  name="nameAndsurname"
                  component="label"
                  className="errorMessage__label"
                />
              </div>

              <div className="formik_field-container">
                <Field
                  className="formik__field"
                  name="email"
                  id="email"
                  placeholder="Adres e-mail"
                  required
                />
                <ErrorMessage
                  name="email"
                  component="label"
                  className="errorMessage__label"
                />
              </div>

              <div className="formik_field-container">
                <Field
                  className="formik__field"
                  name="phone"
                  id="phone"
                  placeholder="Nr telefonu"
                  required
                />
                <ErrorMessage
                  name="phone"
                  component="label"
                  className="errorMessage__label"
                />
              </div>

              <div className="formik_field-container">
                <Field
                  className="formik__field"
                  name="subject"
                  id="subject"
                  placeholder="Temat"
                  required
                />
                <ErrorMessage
                  name="subject"
                  component="label"
                  className="errorMessage__label"
                />
              </div>
            </div>

            <div className="textarea__container">
              <div className="formik_field-container">
                <Field
                  type="textarea"
                  className="formik__field"
                  name="textarea"
                  id="textarea"
                  as="textarea"
                  control="textarea"
                  placeholder="Treść wiadomości"
                  required
                />

                <ErrorMessage
                  name="textarea"
                  component="label"
                  className="errorMessage__label"
                />
              </div>
            </div>
            <div className="formik__question">
              <div className="formik_question-zawartosc">
                <div className="formik_pytanie__kontener">
                  <p>
                    Rozwiąż zadanie <span>{mathQuestion} =</span>
                  </p>
                </div>

                <Field
                  className="formik__field"
                  type="text"
                  id="mathQuestion"
                  name="mathQuestion"
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                  placeholder="wpisz odpowiedź"
                />
              </div>
              {!isAnswerCorrect && (
                <div className="formik__question-error">
                  <p>Nieprawidłowa odpowiedź.</p>
                </div>
              )}
              {mailValidationTrue && (
                <div className="formik__messeageAfterSubmitTrue">
                  <p>Wiadomość została wysłana poprawnie</p>
                </div>
              )}
              {mailValidationFalse && (
                <div className="formik__messeageAfterSubmitFalse">
                  <p>Wiadomość nie została wysłana, spróbuj ponownie</p>
                </div>
              )}
              <div className="formiButton__container">
                <button className="formik_button" type="submit">
                  Wyślij
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Kontakt;
