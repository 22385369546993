import React, { useState, useEffect } from "react";
import "./Oferta.css";
import { OfertaModule } from "../../components";
import { OfertaData } from "../../libs";
import { client } from "../../client";

const Oferta = ({ OfferRef }) => {
  const [OfferTresci, setOfferTresci] = useState([]);
  const [ListaOfert, setListaOfert] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "tresci"] {
          offer
        }`
      )
      .then((data) => {
        setOfferTresci(data);
      })
      .catch(console.error);
    client
      .fetch(`*[_type == "oferta_lista"] | order(releaseDate desc)`)
      .then((data) => setListaOfert(data))
      .catch(console.error);
  }, []);

  return (
    <div className="oferta__kontener" ref={OfferRef}>
      <div className="oferta__zawartosc sekcja-padding">
        <div className="title__h2">
          {OfferTresci.map((data, index) => (
            <h2 key={index + data.offer.offer_title}>
              {data.offer.offer_title === null ||
              data.offer.offer_title === undefined
                ? "Oferta"
                : data.offer.offer_title}
            </h2>
          ))}

          <span></span>
        </div>
        <div className="subtitle__h4">
          {OfferTresci.map((data, index) => (
            <h4 key={index + data.offer.offer__subtitle}>
              {data.offer.offer__subtitle === null ||
              data.offer.offer__subtitle === undefined
                ? ""
                : data.offer.offer__subtitle}
            </h4>
          ))}
        </div>
        <div className="oferta__kontener-zawartosci">
          {ListaOfert.map((data, index) => (
            <OfertaModule
              key={index + " Moduł Oferty"}
              img={data.oferta_img}
              title={data.oferta_lista_title}
              opis={data}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Oferta;
