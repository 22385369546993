import { createClient, SanityClient } from "@sanity/client";

import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
  projectId: "907llnhj",
  dataset: "production",
  useCdn: false,
  apiVersion: "2023-11-13",
  token:
    "skUVk0CH8dvL66jb0wNSPGqwaY7Qc912bZiyJAYxjohaSzvmHDjxD0xzHzM8zem6aSRLQqmAKvD0qmKY0p6DTZ2bLkatu8UVhAkB43RKswGLdpq23WJKtybP1pr9xx4TySoLejJYbZta1PS09cJhYQtWBNASBV1eRs5FRJhaaoHKFu4ECnCD"
});

const builder = imageUrlBuilder(client);

export const urlFor = source => builder.image(source);
