import React, { useState } from "react";
import "./Nawigacja.css";
import { FaPhoneAlt } from "react-icons/fa";

import { BiMenu } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { Menu, MenuMobilne, SocialMediaIcons } from "../../components";
import { No_LOGO } from "../../assets";

const Nawigacja = ({
  AboutRef,
  OfferRef,
  KontaktRef,
  Telefon,
  FacebookLink,
  InstagramLink,
  YouTubeLink
}) => {
  const [menuMobilne, setmenuMobilne] = useState(false);
  return (
    <div className="nawigacja__kontener">
      <div className="nawigacja__panel-gorny">
        <div className="nawigacja__panel-gorny_zawartosc sekcja-padding_nawigacja">
          <div className="nawigacja__panel-gorny-socialMedia">
            <div className="nawigacja__panel-gorny-socialMedia-icon_kontener">
              <SocialMediaIcons
                FacebookLink={FacebookLink}
                InstagramLink={InstagramLink}
                YouTubeLink={YouTubeLink}
              />
            </div>
            <div className="nawigacja__panel-gorny-hamburgerIcon">
              {!menuMobilne ? (
                <button onClick={() => setmenuMobilne(true)}>
                  <BiMenu />
                </button>
              ) : (
                <button onClick={() => setmenuMobilne(false)}>
                  <AiOutlineClose />
                </button>
              )}
            </div>
          </div>
          <div className="nawigacja__panel-gorny-kontakt">
            <p>
              <span>
                <FaPhoneAlt />
              </span>{" "}
              <a href={`tel:+48-${Telefon}`}>{Telefon}</a>
            </p>
          </div>
        </div>
      </div>
      <div className="menuMobilne__kontener">
        {menuMobilne && (
          <MenuMobilne
            OfferRef={OfferRef}
            AboutRef={AboutRef}
            KontaktRef={KontaktRef}
          />
        )}
      </div>
    </div>
  );
};

export default Nawigacja;
