import React, { useState, useEffect } from "react";
import "./Header.css";
import { Menu, SliderV1 } from "../../components/";
import { client, urlFor } from "../../client";
import { No_IMG } from "../../assets";

const Header = ({ AboutRef, OfferRef, KontaktRef, Logo, Nazwa_Firmy }) => {
  const [SliderDATA_SANITY, setSliderDATA_SANITY] = useState([]);
  let SliderLENGTH;
  useEffect(() => {
    client
      .fetch(
        `*[_type == "slider"]{
        slider_title,
        slider_img,
        slider_text
    }`
      )
      .then((data) => {
        setSliderDATA_SANITY(data);
      })
      .catch((error) => error.console);
  }, []);
  const SliderTITLE = [];
  const SliderTEXT = [];
  const SliderIMG = [];
  SliderDATA_SANITY.map((data, index) => {
    SliderTITLE.push(data.slider_title);
    SliderTEXT.push(data.slider_text);
    if (data.slider_img === null || data.slider_img === undefined) {
      SliderIMG.push(No_IMG);
    } else {
      SliderIMG.push(urlFor(data.slider_img));
    }
  });
  SliderLENGTH = SliderDATA_SANITY.length;

  // OG IMG

  const pageOG_Img = document.querySelector('meta[property="og:image"]');
  pageOG_Img.content = `${SliderIMG[0]}`;

  return (
    <div className="header__kontener">
      <div className="header__top-section sekcja-padding_nawigacja">
        <div className="header__logo">
          <img src={Logo} alt={`Logo ${Nazwa_Firmy}`} />
          <p>
            <span>{Nazwa_Firmy}</span>
          </p>
        </div>
        <Menu AboutRef={AboutRef} OfferRef={OfferRef} KontaktRef={KontaktRef} />
      </div>
      <SliderV1
        SliderTITLE={SliderTITLE}
        SliderTEXT={SliderTEXT}
        SliderIMG={SliderIMG}
        SliderDATA_SANITY={SliderDATA_SANITY}
        SliderLENGTH={SliderLENGTH}
      />
    </div>
  );
};

export default Header;
