import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { client } from "./client";
import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-KPC5D8G5PC";
ReactGA.initialize(GA_MEASUREMENT_ID, { debug: true });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
