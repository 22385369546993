import React from "react";
import "./Menu.css";

const Menu = ({ AboutRef, OfferRef, KontaktRef }) => {
  const hanldeScroll = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="menu__kontenerDestkop ">
      <a href="#">
        Strona Główna <span></span>
      </a>
      <a
        href="#firmie"
        onClick={() => {
          hanldeScroll(AboutRef);
        }}>
        O firmie<span></span>
      </a>
      <a
        href="#oferta"
        onClick={() => {
          hanldeScroll(OfferRef);
        }}>
        Oferta<span></span>
      </a>

      <a
        href="#kontakt"
        onClick={() => {
          hanldeScroll(KontaktRef);
        }}>
        Kontakt<span></span>
      </a>
    </div>
  );
};

export default Menu;
