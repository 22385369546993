import React from "react";
import "./OfertaModule.css";
import { urlFor } from "../../client";
import BodyText from "../BodyText/BodyText";
import { No_IMG } from "../../assets";

const OfertaModule = ({ img, title, opis }) => {
  return (
    <div className="ofertamodule__kontener">
      <div
        className="ofertamodule__img-section"
        style={{
          backgroundImage: `url(${
            img === undefined || img === null ? No_IMG : urlFor(img)
          })`
        }}>
        <div className="ofertamodule__img-section__overlay"></div>
        <div className="ofertamodule__title-section">
          <h3>{title}</h3>
        </div>
      </div>
      <div className="ofertamodule__opis">
        
        <BodyText doc={opis} />
      </div>
    </div>
  );
};

export default OfertaModule;
